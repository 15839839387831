<template>
  <div v-show="currentQuestion.question_id" class="question-detail">
    <div class="subject-content">
      <div class="subject-main">
        <div class="title">
          <slot name="title">
            <div class="wrap">{{questionTypeMap[currentQuestion.type]}}</div>
          </slot>
        </div>
        <div class="content">
          <div class="subject">
            <div v-html="currentQuestion.contents"></div>
          </div>
          <div class="reply">
            <ul v-if="isChoice(currentQuestion.type)" class="choice-question">
              <li v-for="(item,index) in currentQuestion.answers||[]" :key="item.id" :class="{show:explainVisible,correct:item.is_right>0,wrong:item.is_right<1,active:choiceAnswer.includes(item.contents)}" @click="selectOption(item)">
                <span class="option">{{getOption(item.contents,index,currentQuestion.type).option}}</span>
                <span class="value">{{getOption(item.contents,index).label}}</span>
              </li>
            </ul>
            <div v-else class="subjective-question">
              <el-input type="textarea" placeholder="请输入答案" v-model="answer"></el-input>
            </div>
          </div>
          <div v-show="explainVisible" class="explain">
            <div v-if="isChoice(currentQuestion.type)" class="result">
              <span>正确答案：{{getCorrectAswerStr()}} </span>
              <span>您的答案：{{getMyAnswerStr()}} </span>
            </div>
            <div v-else class="answer">
              <div class="label">我的回答</div>
              <div class="value">
                {{currentQuestion.user_answer}}
              </div>
            </div>
            <div class="statistics">
              全站统计：本题共被作答
              <span class="text-main">{{currentQuestion.total_num}}</span>
              次，正确率为
              <span class="text-main" v-if="currentQuestion.total_num">{{Math.round(currentQuestion.right_num/currentQuestion.total_num*100)}}%</span>
              <span class="text-main" v-else>0%</span>
            </div>
            <div class="analysis">
              <div class="up">
                <span class="from">来源：{{currentQuestion.author}}</span>
                答案解析
              </div>
              <div class="down desc">
                <div v-html="currentQuestion.analyze"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="action clearfix">
          <div class="left btn-group">
            <a href="javascript:;" @click="showExplain">
              <img class="icon" src="../../../assets/images/icon-answer.png" alt="">
              <span v-if="explainVisible">关闭解析</span>
              <span v-else>显示解析</span>
            </a>
            <a href="javascript:;" @click="correct">
              <img class="icon" src="../../../assets/images/icon-correct.png" alt="">
              <span>纠错</span>
            </a>
            <a href="javascript:;" @click="collect(currentQuestion)">
              <img v-if="currentQuestion.is_like>0" class="icon" src="../../../assets/images/icon-collect-2.png" alt="">
              <img v-else class="icon" src="../../../assets/images/icon-collect.png" alt="">
              <span>收藏</span>
            </a>
          </div>
          <div class="right">
            <template v-if="isChoice(currentQuestion.type)">
              <button v-if="!answerd" v-loading="commitLoading" class="btn" @click="commit">提交答案</button>
              <button v-else-if="!currentQuestion.isLast" class="btn" @click="toNext">下一题</button>
            </template>
            <template v-else>
              <template v-if="!answerd">
                <button v-if="!subjectiveSubmited" v-loading="commitLoading" class="btn" @click="subjectiveCommit">提交答案</button>
                <template v-else>
                  <button v-loading="wrongLoading" class="btn wrong" @click="commit(0)">我答错了</button>
                  <button v-loading="correctLoading" class="btn correct" @click="commit(1)">我答对了</button>
                </template>
              </template>
              <button v-else-if="!currentQuestion.isLast" class="btn" @click="toNext">下一题</button>
            </template>
          </div>
        </div>
      </div>

      <div v-show="answerd" class="module-notes">
        <div class="split-line-10"></div>
        <question-note :obj_id="currentQuestion.question_id" :question_id="currentQuestion.question_id"></question-note>
      </div>
    </div>

    <el-dialog custom-class="h-dialog" width="6rem" title="" :show-close="false" :visible.sync="correctVisible" append-to-body>
      <correct-answer :obj_id="currentQuestion.question_id" :question_id="currentQuestion.question_id" @cancel="correctVisible=false" @correctSuccess="correctVisible=false"></correct-answer>
    </el-dialog>
  </div>
</template>
<script>
import questionNote from './questionNote'
import correctAnswer from './correctAnswer'
import dic from '@/util/dic'
import { setAnswer, doLike, unLike } from '@/api/exam'
export default {
  components: {
    questionNote,
    correctAnswer
  },
  data() {
    return {
      questionTypeMap: dic.questionTypeMap,
      commitLoading: false,
      correctVisible: false,
      answer: '',
      choiceAnswer: [],
      is_right: '',
      subjectiveSubmited: false,
      explainStatus: false,//记录的是打开/关闭操作
    }
  },
  props: {
    currentQuestion: Object,
    explainVisible: Boolean,
    mode: String | Number
  },
  watch: {
    currentQuestion() {
      this.choiceAnswer = []
      this.answer = ''
      this.subjectiveSubmited = this.answerd
    }
  },
  computed: {
    correctLoading() {
      return this.commitLoading && this.is_right === 1
    },
    wrongLoading() {
      return this.commitLoading && this.is_right === 0
    },
    answerd() {
      return this.currentQuestion.user_answer !== null && this.currentQuestion.user_answer !== ''
    }
  },
  methods: {
    showExplain() {
      let explainVisible = !this.explainVisible
      this.explainStatus = explainVisible
      this.$emit('update:explainVisible', explainVisible)
    },
    correct() {
      this.correctVisible = true
    },
    collect(item) {
      if (item.is_like < 1) {
        doLike({
          obj_id: item.question_id,
          uniqid: this.$store.getters.uniqid,
          type: 1
        }).then(res => {
          this.$message.success(res.msg)
          this.$set(item, 'is_like', 1)
        })
      } else {
        //执行取消点赞
        unLike({
          obj_id: item.question_id,
          uniqid: this.$store.getters.uniqid,
          type: 1
        }).then(res => {
          this.$message.success(res.msg)
          this.$set(item, 'is_like', 0)
        })
      }
    },
    async setAnswer(answer, is_right) {
      this.commitLoading = true
      const res = await setAnswer({
        id: this.currentQuestion.id,
        contents: answer,
        is_right
      })
      this.commitLoading = false

      this.$message.success(res.msg)
      this.$set(this.currentQuestion, 'user_answer', answer)
      this.$emit('commitSuccess', {
        question_id: this.currentQuestion.id,
        explainStatus: this.explainStatus
      })
    },
    async commit(is_right) {
      //提交答案
      let isChoice = this.isChoice(this.currentQuestion.type)
      if (isChoice) {
        if (this.choiceAnswer.length < 1) {
          this.$message.warning('请作答')
          return
        }
        this.commitLoading = true
        let correctAnswers = this.getCorrectAswers()
        let is_right = this.choiceAnswer.every(item => correctAnswers.includes(item)) ? 1 : 0

        this.setAnswer(this.choiceAnswer.join('|'), is_right)

        this.is_right = is_right
        if(!this.explainVisible) {
          setTimeout(() => {
            this.showExplain()
          },10)
        }
      } else {
        if (!this.answer) {
          this.$message.warning('请作答')
          return
        }

        await this.setAnswer(this.answer, is_right);

        //主观题选择答对答错后，自动进入下一题
        if (!this.currentQuestion.isLast) {
          this.$emit('toNext', this.currentQuestion.question_id)
        }

      }
    },
    isChoice(type) {
      return [1, 2, 7, 8].includes(type)
    },
    selectOption(item) {
      let index = this.choiceAnswer.findIndex(val => val === item.contents);
      if (index > -1) {
        this.choiceAnswer.splice(index, 1)
      } else {
        if (this.currentQuestion.type == 1 || this.currentQuestion.type == 7) {
          //单选题或判断题
          this.choiceAnswer = [item.contents]
        } else if (this.currentQuestion.type == 2 || this.currentQuestion.type == 8) {
          //复选题或不定项判断题
          this.choiceAnswer.push(item.contents)
        }
      }
    },
    getOption(content, index, type) {
      if (content.includes('、') > 0 && this.currentQuestion.type !==1) {
        let arr = content.split('、');
        return {
          option: arr[0],
          label: arr[1],
        }
      } else {
        let options = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
        return {
          option: options[index],
          label: content,
        }
      }
    },
    getCorrectAswers() {
      return (this.currentQuestion.answers || []).filter(item => item.is_right > 0).map(item => item.contents)
    },
    getCorrectAswerStr() {
      let arr = []
      this.currentQuestion.answers.forEach((item, index) => {
        if (item.is_right > 0) {
          arr.push(this.getOption(item.contents, index).option)
        }
      })
      return arr.join('、')
    },
    getMyAnswerStr() {
      if (!this.currentQuestion.user_answer) {
        return ''
      }
      let myAnswers = this.currentQuestion.user_answer.split('|');
      let arr = []
      this.currentQuestion.answers.forEach((item, index) => {
        if (myAnswers.includes(item.contents) > 0) {
          arr.push(this.getOption(item.contents, index).option)
        }
      })
      return arr.join('、')
    },
    subjectiveCommit() {
      if (!this.answer) {
        this.$message.warning('请作答')
        return
      }
      this.subjectiveSubmited = true;
      this.$emit('update:explainVisible', true)
    },
    toNext() {
      this.$emit('toNext', this.currentQuestion.question_id)
    }
  }
}
</script>
<style lang="scss" scoped>
.question-detail {
  .split-line-10 {
    height: 10px;
    background: $bgColor;
  }
  .subject-content {
    .subject-main {
      background: #fff;
    }
    .title {
      padding: 0 20px;
      .wrap {
        padding: 22px 10px;
        font-size: 24px;
        font-weight: 500;
        border-bottom: 1px solid $borderColor;
      }
      .right {
        float: right;
        line-height: 28px;
        font-size: 18px;
        img.icon {
          width: 20px;
          height: 20px;
          margin-right: 8px;
          vertical-align: middle;
        }
      }
    }
    .content {
      min-height: 658px;
    }
    .action {
      padding: 17px 20px;
      border-top: 1px solid $borderColor;
      .btn {
        width: 160px;
        height: 36px;
        background: $mainColor;
        border-radius: 18px;
        color: #fff;
        font-weight: 500;
        cursor: pointer;
        margin-left: 10px;
        &.correct {
          width: auto;
          padding: 0 10px;
          background: $color-green;
        }
        &.wrong {
          width: auto;
          padding: 0 10px;
          background: $color-red;
        }
      }
      a {
        img,
        span {
          vertical-align: middle;
        }
        span {
          margin-left: 6px;
        }
      }
      .icon {
        height: 18px;
        vertical-align: middle;
      }
    }
    .content {
      padding: 28px 20px 0;
    }
    .action {
      .left {
        float: left;
        line-height: 36px;
        & > a {
          margin-right: 30px;
          &:hover {
            color: $mainColor;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .right {
        float: right;
      }
    }
    .subject {
      font-size: 18px;
      padding-bottom: 28px;
    }
    .reply {
      .subjective-question {
        height: 400px;
        background: #f7f7fa;
        border-radius: 10px;
        margin-top: -8px;
        padding: 18px 20px;
        .el-textarea {
          width: 100%;
          height: 100%;
        }
        ::v-deep .el-textarea__inner {
          width: 100%;
          height: 100%;
          background: transparent;
          border: none;
          outline: none;
          box-shadow: none;
          padding: 0;
          font-size: 16px;
        }
      }
      .choice-question {
        border-top: 1px solid $borderColor;
        padding-bottom: 10px;
        li {
          margin: 10px 0;
          .option {
            display: inline-block;
            vertical-align: middle;
            width: 30px;
            height: 30px;
            background: #ffffff;
            border: 1px solid $borderColor;
            border-radius: 50%;
            line-height: 30px;
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;
          }
          .value {
            margin-left: 16px;
            vertical-align: middle;
            cursor: pointer;
          }
          &.active {
            .option {
              background: $textMainColor;
              color: #fff;
            }
          }
          &.show.correct {
            .option {
              border-color: $color-green;
              background: $color-green;
              color: #fff;
            }
          }
          &.show.wrong {
            .option {
              border-color: $color-red;
              background: $color-red;
              color: #fff;
            }
          }
        }
      }
    }
    .answer {
      padding-bottom: 22px;
      .label {
        font-size: 18px;
        font-weight: 500;
      }
      .value {
        margin-top: 12px;
        font-size: 16px;
        line-height: 28px;
      }
    }
    .explain {
      min-height: 414px;
      padding: 28px 0;
      border-top: 1px solid $borderColor;
      .result {
        span {
          margin-right: 4px;
        }
      }
      .statistics {
        font-size: 14px;
        color: $textSubColor;
      }
      .analysis {
        margin-top: 8px;
        background: #fff7f3;
        padding: 20px;
        .up {
          position: relative;
          height: 20px;
          line-height: 20px;
          font-size: 18px;
          font-weight: 500;
          &::before {
            content: "";
            position: absolute;
            left: -20px;
            top: 0;
            width: 4px;
            height: 20px;
            background: $mainColor;
          }
          .from {
            float: right;
            font-size: 14px;
            color: $textLightColor;
          }
        }
        .desc {
          margin-top: 12px;
          ::v-deep img,
          ::v-deep video {
            max-width: 100%;
          }
        }
      }
    }

    .module-notes {
      background: #fff;
    }
  }
}
</style>