<template>
  <div class="dialog-main corrent-answer">
    <div class="dialog-head">
      纠错
    </div>
    <div class="input-container">
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item prop="contents">
          <el-input v-model="form.contents" type="textarea" placeholder="请输入内容"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="h-btn2-group">
      <button class="btn" @click="cancel">取消</button>
      <button v-loading="loading" class="btn" @click="sure">确认</button>
    </div>
  </div>
</template>
<script>
import { feedBack } from '@/api/user'
export default {
  data() {
    return {
      form: {
        contents: ''
      },
      rules: {
        contents: [
          { required: true, message: '该项不能为空', trigger: 'blur' }
        ]
      },
      loading: false
    }
  },
  props: {
    obj_id: String | Number
  },
  methods: {
    imageUpdate() {

    },
    cancel() {
      this.$emit('cancel')
    },
    sure() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          feedBack({
            uniqid: this.$store.getters.uniqid,
            type: 2,
            content: this.form.contents
          }).then((res) => {
            this.loading = false
            this.$message.success(res.msg)
            this.$emit('correctSuccess')
          }).catch(err => {
            this.loading = false
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.corrent-answer {
  width: 600px;
  height: 600px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 10px;
  padding: 0 30px;
  .input-container {
    .el-form-item {
      margin-bottom: 0;
    }
    ::v-deep .el-textarea__inner {
      appearance: none;
      background: transparent;
      border: none;
      outline: none;
      box-shadow: none;
      background: #f7f7fa;
      border-radius: 10px;
      padding: 18px 20px;
      height: 380px;
    }
  }
  .h-btn2-group {
    margin-top: 48px;
  }
}
</style>