<template>
  <div class="notes">
    <div class="title">
      <div class="wrap" :class="{border:noteList.length>0}">
        <a href="javascript:;" class="right" @click="publish">
          <img class="icon" src="../../../assets/images/icon-edit.png" alt="">发表笔记
        </a>
        笔记
      </div>
    </div>
    <div class="content">
      <ul>
        <li v-for="item in noteList" :key="item.id">
          <div class="wrap">
            <div class="up">
              <img v-if="item.is_like" @click="like(item)" class="icon" src="../../../assets/images/icon-zan-2.png" alt="">
              <img v-else @click="like(item)" class="icon" src="../../../assets/images/icon-zan.png" alt="">
              用户：{{item.user_nickname}}
            </div>
            <div class="middle">
              <div class="info">
                {{item.contents}}
              </div>
            </div>
            <div class="down">
              <span class="date">{{item.create_time}}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <el-dialog custom-class="h-dialog" width="6rem" title="" :show-close="false" :visible.sync="insertVisible" append-to-body>
      <insert-note :obj_id="obj_id" @cancel="insertVisible=false" @insertSuccess="insertSuccess"></insert-note>
    </el-dialog>
  </div>
</template>
<script>
import insertNote from './insertNote'
import { getQuestionComment, doLike, unLike } from '@/api/exam'
export default {
  components: {
    insertNote
  },
  data() {
    return {
      noteList: [],
      insertVisible: false
    }
  },
  props: {
    question_id: String | Number,
    obj_id: String | Number
  },
  watch: {
    question_id(val) {
      this.getNoteList()
    }
  },
  created() {
    this.getNoteList()
  },
  methods: {
    async getNoteList() {
      if (!this.question_id) return
      const { data } = await getQuestionComment({
        uniqid: this.$store.getters.uniqid,
        question_id: this.question_id
      })
      this.noteList = data.data || []
    },
    publish() {
      this.insertVisible = true
    },
    insertSuccess() {
      this.insertVisible = false
      this.getNoteList()
    },
    like(item) {
      if (!item.is_like) {
        doLike({
          obj_id: item.id,
          uniqid: this.$store.getters.uniqid,
          type: 2
        }).then(res => {
          this.$message.success(res.msg)
          this.$set(item, 'is_like', 1)
        })
      } else {
        //执行取消点赞
        unLike({
          obj_id: item.id,
          uniqid: this.$store.getters.uniqid,
          type: 2
        }).then(res => {
          this.$message.success(res.msg)
          this.$set(item, 'is_like', 0)
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.notes {
  .title {
    padding: 0 20px;
    .wrap {
      padding: 22px 10px;
      font-size: 24px;
      font-weight: 500;
      &.border {
        border-bottom: 1px solid $borderColor;
      }
    }
    .right {
      float: right;
      line-height: 28px;
      font-size: 18px;
      img.icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
        vertical-align: middle;
      }
    }
  }
  .action {
    padding: 17px 20px;
    border-top: 1px solid $borderColor;
    .btn {
      width: 160px;
      height: 36px;
      background: $mainColor;
      border-radius: 18px;
      color: #fff;
      font-weight: 500;
      cursor: pointer;
    }
    .icon {
      height: 18px;
      vertical-align: middle;
    }
  }

  ul {
    padding: 0 20px;
  }
  li {
    padding: 27px 0;
    border-bottom: 1px solid $borderColor;
    &:last-child {
      border-bottom: none;
    }
    .up {
      color: $textSubColor;
      height: 18px;
      line-height: 18px;
      .icon {
        float: right;
        height: 18px;
        cursor: pointer;
      }
    }
    .middle {
      margin-top: 12px;
      line-height: 28px;
    }
    .down {
      margin-top: 11px;
      color: $textLightColor;
    }
  }
}
</style>